ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  text-indent: -5px;
}

.wrap-box-plan {
  box-shadow: 0px 6px 34px rgb(115 111 111 / 6%);
  padding: 24px;
  border-radius: 8px;
}
