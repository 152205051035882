@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "American Captain";
  src: local("American Captain"),
    url(./assets/fonts/AmericanCaptain.woff2) format("woff2");
}

@font-face {
  font-family: "itc-avant-garde-gothic-pro";
  src: local("itc-avant-garde-gothic-pro"),
    url(./assets/fonts/itc-avant-garde-gothic-lt-book-regular.woff2)
      format("woff2");
}

html {
  background-color: #0e121d;
  color: #fff;
  font-family: itc-avant-garde-gothic-pro;
}
