@import url('https://fonts.cdnfonts.com/css/american-captain-2');
@import url('https://fonts.cdnfonts.com/css/itc-avant-garde-gothic-std-book');
@import url('https://fonts.cdnfonts.com/css/itc-avant-garde-gothic-std');

#root{
    background-color: #0e121d;

}

.hero-cont{
    background-image: url("/public/images/header-x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 50vh;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}


.hero-cont .row{
    padding: 0 120px !important;
}

.hero-cont h1{
    font-family: 'American Captain', sans-serif !important;
    font-size: 64px;
    color: hsla(172.8, 77.32%, 61.96%, 1) !important;
    text-align: center;
    margin-top: 80px;
}

@media screen and (max-width: 600px) {
    .hero-cont h1{
        font-size: 48px;
        padding-top: 20px;
    }
    .hero-cont h2{
        padding-top: 20px;
    }    
}

.hero-cont h2{
    font-family: 'ITC Avant Garde Gothic Std', sans-serif;
    color: #FFF;
    font-weight: 200;
    font-size: 19px;
    text-align: center;
    margin-bottom: 3rem;
    letter-spacing: .05em;
}

.hero-cont p{
    font-family: 'ITC Avant Garde Gothic Std', sans-serif;
    font-weight: 300;
    letter-spacing: .1em;
    color:#FFF;    
    text-align: center;
    font-size: 24px;
}

@media (max-width: 992px){
    .hero-cont{
        background-position: center right;
        height: 70vh;
    }

    .hero-cont .row{
        padding: 0 40px !important;
    }    
}

.logo-top{
    max-width: 80px !important;
    position: absolute;
    left: 50%;
    margin-left: -40px;
    padding: 20px 0;
}

video.fullscreen {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width:100%;
    height:100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.card-main-container {
    display: flex;
    padding: 1% 10%;
}
.bgColor{
    background-color: #0e121d;
}
.modal-content{
    width:auto !important;
}
.modal-body{
padding: 0 !important;
}
.modal-lg, .modal-xl{
    --bs-modal-width: 80% !important;
}
video{
    /* height: 380px; */
    width: 100%;
    height: auto;
}
.imagePlay{
    cursor: pointer;
}

@media (min-width: 992px){
    .modal-lg, .modal-xl {
        --bs-modal-width: 80% !important;
    }
}
.card-container {
    background-color: hsla(27.69, 30.23%, 91.57%, 0.07) !important;
}

.main-container {
    background-color: #0E131D;
}

.card-header-text {
    font-family: 'American Captain', sans-serif !important;
    font-family: 'American-Captain';
    text-align: center !important;
    white-space: pre-wrap !important;
    text-decoration: none !important;
    color: hsla(172.8, 77.32%, 61.96%, 1) !important;
    font-size: calc(3 * 1rem) !important;
    font-weight: 500 !important;
    font-style: normal !important;
    letter-spacing: .03em !important;
    text-transform: none !important;
}

.card-text {
    color: hsla(27.69, 30.23%, 91.57%, 1) !important;
    text-align: center;
    font-size: calc(1.2 * 1rem) !important;
    white-space: pre-wrap;
    font-family: 'ITC Avant Garde Gothic Std Book', sans-serif;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: 0.65s;
}

.content-title {
    font-family: 'American Captain', sans-serif !important;
    margin-top: 1rem;
    font-size: calc(1.2* 1rem) !important;
    font-weight: 600 !important;
    color: hsla(27.69, 30.23%, 91.57%, 1) !important;
}

.content-sub-title {
    font-family: 'ITC Avant Garde Gothic Std Book', sans-serif;
    color: hsla(27.69, 30.23%, 91.57%, 1) !important;
    margin-top: 1% !important;
    font-size: calc(0.9* 1rem) !important;
    font-weight: 400 !important;
}

.h2size{
    font-size: 20px;
    color:#292524;
}

ul {
    margin:0 !important;
    
}

li {
    color: white !important;
    font-size: 16px;
    margin: 0;
}

.text-\[\#53e9d7\] {
    margin: 0;
}

.bg-stone-800 {
    color: white !important;
}

label{
    color: white !important;
}

.font-heading {
    color: #53e9d7;
}

.react-multiple-carousel__arrow{
    background-color: #53e9d7;
}

.font-extrabold{
    color: #53e9d7;
}

#confirmation-step{
    color: #fff !important
}